<template>
  <disaster-rrcovery />
</template>

<script>
import DisasterRrcovery from "../../components/solutions/data-center/DisasterRrcovery.vue";
export default {
  components: { DisasterRrcovery },
  name: "disaster-recovery-page",
};
</script>
