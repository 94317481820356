<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-green h1">How can we help?</h2>
      <p class="desc">
        Our disaster recovery services enable you to get back to work as soon as
        possible. We authorize and ensure data recoveries because all of our
        solutions are automatically designed to work efficiently and have been
        tested and tried out to get the job done whenever they are needed.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/disaster-recovery/it-business-continuity2x.png"
        alt="How can we help?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "third-dis-section",
};
</script>
