<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">A better Approach to Disaster Recovery</h2>
      <p class="desc">
        Our managed Disaster Recovery services, deliver certified recoveries of
        complete solutions, ready for users to implement in case of a disaster.
        To be able to certify and therefore guarantee recoveries, all of our
        solutions are automatically configured to work flawlessly and are tested
        to application-level on a daily basis to be certain they will work if
        needed.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/disaster-recovery/dr-bc-body-1.png"
        alt="A better Approach to Disaster Recovery"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-dis-section",
};
</script>
